// Colores Oficiales
$azul_sepla: rgb(30, 90, 160);
$gris_sepla: rgb(80, 80, 80);
$rojo_sepla: rgb(170, 70, 70);

// Calendario

.fc-center h2 {
    color: $azul_sepla !important;
    font-size: 16px !important;
    font-weight: 700 !important;
    text-align: center !important;
}

.fc-button-primary {
    background-color: $azul_sepla !important;
    font-size: 13px !important;
    font-weight: 600 !important;
}

.fc-title {
    font-size: 13px !important;
    font-weight: 600 !important;
}

.fc-day-number {
    color: $azul_sepla !important;
    font-weight: 600 !important;
}

.fc-day-header {
    color: white !important;
    background-color: $azul_sepla !important;
}

// Modal

.modal-backdrop {
    background-color: $azul_sepla !important;
    opacity: 0.3 !important;
}
